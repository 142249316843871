import React, { useContext, useRef } from "react";
import { routes } from "../config/routes";
import Logo from "../assets/images/logo.svg";
import LogoDark from "../assets/images/LogoDark.svg";
import Pl from "../assets/images/poland.svg";
import Eng from "../assets/images/uk.svg";
import Hamburger from "./Hamburger";
import { Container } from "react-bootstrap";
import { useI18next, Link } from "gatsby-plugin-react-i18next";
import { DictionaryContext } from "../apis/dictionaryContext";
import Cookies from "js-cookie";
import classNames from "classnames";
import Right from "../assets/images/right.svg";
import { arrayParse } from "../config/parse";
import { graphql, useStaticQuery } from "gatsby";
import slugify from "slugify";
import ArrowRight from "../assets/images/arrowRight.svg";
import { useTranslation } from "react-i18next";

const query = graphql`
  query ServicesToNavigationQuery {
    allStrapiService(sort: { order: ASC }, filter: { order: { ne: 999 } }) {
      nodes {
        service_title
        order
        locale
        icon_render
        navigation_section
        localizations {
          data {
            attributes {
              locale
              service_title
              icon_render
              order
              navigation_section
            }
          }
        }
      }
    }
    menuData {
      menu {
        attributes {
          title
          slug
          items {
            data {
              attributes {
                url
                title
                children {
                  data {
                    attributes {
                      url
                      title
                      children {
                        data {
                          attributes {
                            title
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Navigation = ({ dark }) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openSubmenu, setOpenSubmenu] = React.useState(-1);
  const [scrolled, setScrolled] = React.useState(false);
  const { language, originalPath } = useI18next();
  const dictionary = useContext(DictionaryContext);
  const plRef = React.useRef();
  const enRef = React.useRef();
  const { t } = useTranslation();

  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarOnScroll);
  }, []);

  React.useEffect(() => {
    const rememberLng = Cookies.get("n7mobile.language.remember");
    if (rememberLng) {
      if (rememberLng === "en" && language !== "en") {
        enRef?.current.click();
      }
      if (rememberLng === "pl" && language !== "pl") {
        plRef?.current.click();
      }
    } else {
      if (navigator && navigator.language === "pl-PL" && language !== "pl") {
        plRef?.current.click();
      }
    }
  }, []);

  const changeNavbarOnScroll = () => {
    setOpenMenu(false);
    if (window.scrollY > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const layerMenuClose = () => {
    setOpenMenu(false);
  };

  const {
    allStrapiService,
    menuData: { menu },
  } = useStaticQuery(query);
  const filteredServices = arrayParse(
    allStrapiService.nodes.filter((item) => item.locale === language),
    ["order", "navigation_section"],
  );
  const headerRef = useRef();
  const {
    attributes: {
      items: { data },
    },
  } = menu.find((el) => el.attributes.slug === language);

  return (
    <header
      ref={headerRef}
      className={classNames(
        scrolled ? "Header scrolled" : "Header",
        dark && "dark",
      )}
    >
      <div
        className={openMenu ? "menuLayer active" : "menuLayer"}
        onClick={layerMenuClose}
        role="none"
      />
      <Container>
        <div className="Header__wrapper">
          <Link to={routes.homepage} className="logoLink">
            {dark ? <LogoDark /> : <Logo />}
          </Link>
          <div
            className={openMenu ? "mobileContainer active" : "mobileContainer"}
          >
            <nav className="Navigation">
              <ul className="Navigation__list">
                {data.map((link, index) => (
                  <React.Fragment key={index}>
                    {link.attributes.children.data.length === 0 ? (
                      <li className="Navigation__list-item">
                        <Link
                          to={link.attributes.url}
                          onClick={() => setOpenMenu(false)}
                          activeClassName={"active"}
                          partiallyActive={true}
                        >
                          {link.attributes.title}
                        </Link>
                      </li>
                    ) : (
                      <>
                        <li
                          className="Navigation__list-item"
                          style={{
                            position:
                              link.attributes.children.data.length === 1
                                ? "relative"
                                : "",
                          }}
                          onMouseEnter={() => {
                            headerRef.current.classList.add("blackColor");
                          }}
                          onMouseLeave={() => {
                            headerRef.current.classList.remove("blackColor");
                          }}
                        >
                          <Link
                            activeClassName={"active"}
                            partiallyActive={true}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              if (openSubmenu === index) {
                                setOpenSubmenu(-1);
                              } else {
                                setOpenSubmenu(index);
                              }
                            }}
                          >
                            {link.attributes.title}
                            <Right />
                          </Link>

                          <div
                            className={classNames(
                              "submenu",
                              link.attributes.children.data.length === 1 &&
                                "submenu--small",
                              openSubmenu === index && "active",
                            )}
                          >
                            <Container>
                              {link.attributes.children.data.map(
                                (subLink, index) => (
                                  <div
                                    key={index}
                                    className={`linkColumn ${!!subLink.attributes.url ? "br" : ""}`}
                                  >
                                    {!!subLink.attributes.url ? (
                                      <Link
                                        to={subLink.attributes.url}
                                        activeClassName={"active"}
                                        onClick={() => setOpenMenu(false)}
                                        className={
                                          "submenu-link title text-decoration-underline ml-0-imp"
                                        }
                                      >
                                        {subLink.attributes.title}
                                      </Link>
                                    ) : (
                                      <>
                                        {subLink.attributes.title !==
                                        "EMPTY" ? (
                                          <p className="title d-block mt-4 mt-lg-0">
                                            {subLink.attributes.title}
                                          </p>
                                        ) : (
                                          <div className={"mt-4 mt-lg-0"}></div>
                                        )}
                                      </>
                                    )}
                                    <>
                                      {subLink.attributes.children.data.map(
                                        (subSubLink, index) => (
                                          <Link
                                            key={index}
                                            onClick={() => setOpenMenu(false)}
                                            className={`submenu-link ${subLink.attributes.title === "EMPTY" ? "ms-0" : ""}`}
                                            activeClassName={"active"}
                                            to={subSubLink.attributes.url}
                                          >
                                            {subSubLink.attributes.title}
                                            <ArrowRight />
                                          </Link>
                                        ),
                                      )}
                                    </>
                                  </div>
                                ),
                              )}
                            </Container>
                          </div>
                        </li>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </nav>
            <div className="LanguagesSelector">
              <ul className="LanguageList">
                <li className="LanguageList__item">
                  <Link
                    ref={plRef}
                    className="lngButton"
                    to={originalPath}
                    language={"pl"}
                    onClick={() =>
                      Cookies.set("n7mobile.language.remember", "pl", {
                        expires: 365,
                      })
                    }
                  >
                    <Pl />
                  </Link>
                </li>
                <li className="LanguageList__item">
                  <Link
                    ref={enRef}
                    className="lngButton"
                    to={originalPath}
                    language={"en"}
                    onClick={() =>
                      Cookies.set("n7mobile.language.remember", "en", {
                        expires: 365,
                      })
                    }
                  >
                    <Eng />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="contactButton">
              <Link
                to={routes.contact}
                className={"buttonLink"}
                onClick={() => setOpenMenu(false)}
              >
                <span> {dictionary.contact[language]}</span>
              </Link>
            </div>
          </div>
          <Hamburger isMenuOpened={openMenu} setIsMenuOpened={setOpenMenu} />
        </div>
      </Container>
    </header>
  );
};

export default Navigation;
