import React from "react";
import classnames from "classnames";

const Paragraph = ({
  children,
  yellow,
  gray,
  dark,
  center,
  bold,
  big,
  semiBold,
  medium,
  small,
  darkText,
  muted,
  tag = "div",
  paragraph18,
}) => {
  const Tag = `${tag}`;
  return (
    <Tag
      className={classnames({
        paragraph: true,
        big: big,
        small: small,
        "paragraph-18": paragraph18,
        "gray-text": gray,
        "dark-text": dark,
        "text-darkText": darkText,
        "text-center": center,
        "fw-semi": semiBold,
        "fw-bold": bold,
        "fw-medium": medium,
        "text-yellow": yellow,
        "text-muted": muted,
      })}
    >
      {children}
    </Tag>
  );
};

export default Paragraph;
