// import { addUtmToDataLayer, initUtmTracking } from "./src/config/utm-tracking";
//
// const onRouteUpdate = ({ location, prevLocation }) => {
//   if (!!location.hash) {
//     return false;
//   }
//   if (typeof window !== "undefined") {
//     addUtmToDataLayer("page_view", {
//       event_category: "navigation",
//       event_action: "page_view",
//       page_path: location.pathname,
//       page_previous: prevLocation ? prevLocation.pathname : "",
//     });
//   }
//   window.scrollTo({ top: 0, left: 0, behavior: "instant" });
//   return false;
// };
//
// const onClientEntry = () => {
//   if (typeof window !== "undefined") {
//     initUtmTracking();
//     window.addEventListener("DOMContentLoaded", () => {
//       console.log("UTM INIT !!!!!!!!!!!");
//     });
//   }
// };
//
// export { onClientEntry, onRouteUpdate };
//
// // const onRouteUpdate = ({ location, prevLocation }) => {
// //   // Usuń lub przeorganizuj warunek hash jeśli to potrzebne
// //   if (!!location.hash) {
// //     return false;
// //   }
// //
// //   // Użyj setTimeout, aby zapewnić, że kod wykonuje się po pełnym załadowaniu strony
// //   setTimeout(() => {
// //     if (typeof window !== "undefined") {
// //       addUtmToDataLayer("page_view", {
// //         event_category: "navigation",
// //         event_action: "page_view",
// //         page_path: location.pathname,
// //         page_previous: prevLocation ? prevLocation.pathname : "",
// //       });
// //       window.scrollTo({ top: 0, left: 0, behavior: "instant" });
// //     }
// //   }, 0);
// //
// //   return false;
// // };
// //
// // const onClientEntry = () => {
// //   // Zamiast czekać na DOMContentLoaded, możemy użyć alternatywnego podejścia
// //   if (typeof window !== "undefined") {
// //     // Jeśli DOM już się załadował, wywołaj initUtmTracking od razu
// //     if (
// //       document.readyState === "complete" ||
// //       document.readyState === "interactive"
// //     ) {
// //       console.log("UTM INIT !!!!!!!!!!!");
// //       initUtmTracking();
// //     } else {
// //       // W przeciwnym razie dodaj event listener
// //       window.addEventListener("DOMContentLoaded", () => {
// //         console.log("UTM INIT !!!!!!!!!!!");
// //         initUtmTracking();
// //       });
// //     }
// //   }
// // };

import { addUtmToDataLayer, initUtmTracking } from "./src/config/utm-tracking";

export const onInitialClientRender = () => {
  if (typeof window !== "undefined") {
    console.log("UTM INIT !!!!!!!!!!!");
    initUtmTracking();
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Daj trochę czasu na pełne załadowanie strony przed wykonaniem śledzenia
  setTimeout(() => {
    if (typeof window !== "undefined" && !location.hash) {
      addUtmToDataLayer("page_view", {
        event_category: "navigation",
        event_action: "page_view",
        page_path: location.pathname,
        page_previous: prevLocation ? prevLocation.pathname : "",
      });

      // Przewijanie do góry strony po zmianie trasy
      window.scrollTo(0, 0);
    }
  }, 100);

  return true; // Zwróć true, aby pozwolić na domyślne zachowanie Gatsby
};
