const getUtmParams = () => {
  if (typeof window === "undefined") return {};

  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {};

  const utmParamsList = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
  ];

  utmParamsList.forEach((param) => {
    const value = urlParams.get(param);
    if (value) utmParams[param] = value;
  });

  return utmParams;
};

const saveUtmToCookies = (utmParams) => {
  if (typeof window === "undefined" || Object.keys(utmParams).length === 0)
    return;
  const expiryDate = new Date();
  Object.entries(utmParams).forEach(([param, value]) => {
    document.cookie = `${param}=${value}; path=/; SameSite=Lax`;
  });
  document.cookie = `utm_data=${JSON.stringify(utmParams)}; path=/; SameSite=Lax`;
};

const getUtmFromCookies = () => {
  if (typeof window === "undefined") return {};

  const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
  const utmParams = {};

  const utmParamsList = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
  ];

  utmParamsList.forEach((param) => {
    const cookie = cookies.find((c) => c.startsWith(`${param}=`));
    if (cookie) {
      utmParams[param] = cookie.split("=")[1];
    }
  });

  return utmParams;
};

const addUtmToDataLayer = (eventName, eventData = {}) => {
  if (typeof window === "undefined") return;
  window.dataLayer = window.dataLayer || [];
  const utmParams = getUtmFromCookies();
  window.dataLayer.push({
    event: eventName,
    ...eventData,
    ...utmParams,
  });
};

const initUtmTracking = () => {
  if (typeof window === "undefined") return;

  const utmParams = getUtmParams();

  if (Object.keys(utmParams).length > 0) {
    saveUtmToCookies(utmParams);
    addUtmToDataLayer("utm_acquisition", {
      event_category: "acquisition",
      event_action: "landing",
      landing_page: window.location.pathname,
    });
  }
};

export { initUtmTracking, addUtmToDataLayer, getUtmFromCookies };
