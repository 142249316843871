exports.components = {
  "component---src-layouts-blog-jsx": () => import("./../../../src/layouts/blog.jsx" /* webpackChunkName: "component---src-layouts-blog-jsx" */),
  "component---src-layouts-news-jsx": () => import("./../../../src/layouts/news.jsx" /* webpackChunkName: "component---src-layouts-news-jsx" */),
  "component---src-layouts-project-jsx": () => import("./../../../src/layouts/project.jsx" /* webpackChunkName: "component---src-layouts-project-jsx" */),
  "component---src-layouts-service-jsx": () => import("./../../../src/layouts/service.jsx" /* webpackChunkName: "component---src-layouts-service-jsx" */),
  "component---src-layouts-work-offer-jsx": () => import("./../../../src/layouts/workOffer.jsx" /* webpackChunkName: "component---src-layouts-work-offer-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-career-jsx": () => import("./../../../src/pages/career.jsx" /* webpackChunkName: "component---src-pages-career-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-apps-pricing-jsx": () => import("./../../../src/pages/mobile-apps-pricing.jsx" /* webpackChunkName: "component---src-pages-mobile-apps-pricing-jsx" */),
  "component---src-pages-ott-development-jsx": () => import("./../../../src/pages/ott-development.jsx" /* webpackChunkName: "component---src-pages-ott-development-jsx" */),
  "component---src-pages-ott-development-play-now-jsx": () => import("./../../../src/pages/ott-development/play-now.jsx" /* webpackChunkName: "component---src-pages-ott-development-play-now-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-pricing-jsx": () => import("./../../../src/pages/services-pricing.jsx" /* webpackChunkName: "component---src-pages-services-pricing-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */)
}

